@media screen and (max-width: 767px) {
  .cart-title {
    margin-top: -24px;
  }
}

@media screen and (max-width: 767px) {
  .cart-wrapper > .-main:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  .cart-wrapper {
    display: flex;
  }
  .cart-wrapper > .-main {
    position: relative;
    flex-grow: 1;
  }
  .cart-wrapper > .-sidebar {
    flex: 0 0 260px;
    margin: 0 0 0 20px;
    flex-grow: 0;
    padding-top: 15px;
  }
  .cart-wrapper > .-sidebar .cart-total {
    width: 260px;
  }
}
@media screen and (min-width: 1024px) {
  .cart-wrapper > .-sidebar {
    flex: 0 0 310px;
    margin: 0 0 0 30px;
  }
  .cart-wrapper > .-sidebar .cart-total {
    width: 310px;
  }
}

.sticky {
  position: sticky;
  top: 15px;
  left: 0;
}
@media screen and (max-width: 575px) {
  .sticky {
    position: static;
  }
}

.cart-item {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 0 15px;
}
.cart-item:not(:last-child) {
  margin-bottom: 26px;
  border-bottom: 1px solid #DADADA;
}
.cart-item .cart-item {
  flex: 0 0 100%;
  padding-top: 0;
}
.cart-item .ci-also-products {
  margin-bottom: 26px;
  font-weight: 600;
}
.cart-item .ci-image {
  flex: 0 0 30%;
  padding-right: 15px;
}
.cart-item .ci-image img {
  width: 100%;
}
.cart-item .ci-data {
  flex: 0 0 70%;
}
.cart-item .ci-complect-title {
  flex: 0 0 100%;
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 12px;
}
.cart-item .ci-title {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}
.cart-item .ci-title a:not([href]) {
  cursor: text;
}
.cart-item .ci-title a:not([href]):hover, .cart-item .ci-title a:not([href]):active, .cart-item .ci-title a:not([href]):focus {
  color: #000;
}
.cart-item .ci-props {
  text-align: left;
  color: #706F6F;
  font-weight: 400;
  font-size: 11px;
}
.cart-item .ci-props span {
  display: block;
}
.cart-item .ci-order {
  display: flex;
  justify-content: space-between;
}
.cart-item .ci-nof {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.cart-item .ci-nof .button.is-add,
.cart-item .ci-nof .button.is-delete {
  width: 30px;
  height: 30px;
}
.cart-item .ci-nof .input {
  width: 60px;
  margin: 0;
  padding: 0 9px;
  box-sizing: border-box;
  text-align: right;
}
.cart-item .ci-nof .button:not(:last-child),
.cart-item .ci-nof .input:not(:last-child) {
  margin-right: 4px;
}
.cart-item .ci-prices {
  text-align: right;
  font-size: 17px;
  line-height: 18px;
  margin-top: -5px;
}
.cart-item .ci-price {
  white-space: nowrap;
  font-size: 12px;
}
.cart-item .ci-cost {
  white-space: nowrap;
  font-weight: 600;
}
.cart-item .ci-delete {
  display: flex;
  justify-content: flex-end;
}
.cart-item .ci-delete > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-top: -4px;
}
.cart-item .ci-delete > a, .cart-item .ci-delete > a:hover {
  background-color: transparent;
}
.cart-item .ci-delete > a:before, .cart-item .ci-delete > a:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #C6C6C6;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}
.cart-item .ci-delete > a:before {
  width: 50%;
  height: 1px;
}
.cart-item .ci-delete > a:after {
  width: 1px;
  height: 50%;
}
.cart-item .ci-delete > a:hover:before, .cart-item .ci-delete > a:hover:after {
  background-color: #878787;
}
@media screen and (max-width: 767px) {
  .cart-item {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100vw;
  }
  .cart-item:not(:last-child) {
    margin-bottom: 10px;
  }
  .cart-item .cart-item {
    position: relative;
    padding-top: 0;
  }
  .cart-item .ci-order {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .cart-item .ci-price {
    margin-right: 6px;
  }
  .cart-item .ci-prices {
    order: 1;
    display: flex;
    align-items: baseline;
    margin-bottom: 6px;
  }
  .cart-item .ci-nof {
    order: 2;
  }
  .cart-item .ci-delete {
    position: absolute;
    top: 0;
    right: -20px;
    margin-top: 0;
  }
  .cart-item .ci-title {
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 768px) {
  .cart-item {
    padding: 11px 0 16px;
  }
  .cart-item .ci-complect-title {
    font-size: 19px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 30px;
  }
  .cart-item .ci-data {
    flex: 0 0 70%;
    display: flex;
  }
  .cart-item .ci-image {
    flex: 0 0 30%;
    padding-right: 28px;
  }
  .cart-item .ci-image a {
    display: block;
  }
  .cart-item .ci-title {
    flex: 0 0 52%;
    font-size: 13px;
    line-height: 17px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .cart-item .ci-order {
    flex: 0 0 38%;
    justify-content: flex-start;
  }
  .cart-item .ci-delete {
    width: 10%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cart-item .ci-order {
    flex-direction: column;
  }
  .cart-item .ci-nof {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .cart-item .ci-title {
    font-size: 16px;
  }
  .cart-item .ci-props {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.5;
    padding-top: 2px;
  }
  .cart-item .ci-order {
    justify-content: space-between;
    gap: 8px;
  }
}

@media screen and (max-width: 767px) {
  .modal-scroll-box .cart-item {
    position: relative;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .modal-scroll-box .cart-item .ci-title {
    font-size: 18px;
    padding-right: 15px;
    line-height: 1.2;
  }
  .modal-scroll-box .cart-item .ci-delete {
    top: 0;
    margin-top: 0;
    right: 0;
  }
}

.cart-complect {
  display: flex;
  flex-wrap: wrap;
}
.cart-complect .cc-title {
  flex: 0 0 100%;
  font-size: 14px;
  line-height: 1.2;
}
.cart-complect .cc-parts {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}
.cart-complect .cc-parts-props {
  font-size: 11px;
  line-height: 18px;
  color: #706F6F;
  letter-spacing: 0;
  display: block;
}
.cart-complect .cc-order {
  display: flex;
  justify-content: space-between;
}
.cart-complect .cc-nof .input {
  width: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.cart-complect .cc-nof .button:not(:last-child),
.cart-complect .cc-nof .input:not(:last-child) {
  margin-right: 4px;
}
.cart-complect .cc-prices {
  text-align: right;
  font-size: 17px;
  line-height: 18px;
  margin-top: -5px;
}
.cart-complect .cc-price {
  white-space: nowrap;
  font-size: 12px;
}
.cart-complect .cc-cost {
  font-weight: 600;
}
.cart-complect .cc-toggle {
  cursor: pointer;
  position: relative;
  display: inline-block;
  line-height: 1.2;
}
.cart-complect .cc-toggle::after {
  content: "";
  display: block;
  position: absolute;
  right: -18px;
  top: 8px;
  width: 10px;
  height: 6px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='#1D1D1B' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.68 1.189L4.867 6 0 1.189 1.189 0l3.679 3.623L8.49 0l1.188 1.189z'/%3E%3C/svg%3E%0A");
}
.cart-complect .cc-toggle.is-active::after {
  transform: rotate(180deg);
  top: 6px;
}
.cart-complect .cc-fake-image {
  display: block;
  width: calc(30vw - 40px);
  height: calc(30vw - 40px);
  border: 1px solid #878787;
}
@media screen and (max-width: 767px) {
  .cart-complect {
    margin: 30px 0 -30px;
    padding-bottom: 10px;
  }
  .cart-complect.is-opened {
    margin-bottom: -30px;
    padding-top: 10px;
  }
  .cart-complect .cc-title {
    padding: 2px 0 10px;
    color: #BB0026;
  }
  .cart-complect .cc-title .cc-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='#BB0026' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.68 1.189L4.867 6 0 1.189 1.189 0l3.679 3.623L8.49 0l1.188 1.189z'/%3E%3C/svg%3E%0A");
  }
  .cart-complect .cc-toggle {
    padding-bottom: 8px;
  }
  .cart-complect.is-opened, .cart-complect.is-opened + .cart-complect {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100vw;
    background: #EDEDED;
  }
  .cart-complect .cc-order {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  .cart-complect .cc-price {
    margin-right: 6px;
  }
  .cart-complect .cc-prices {
    order: 1;
    display: flex;
    align-items: baseline;
    margin-bottom: 6px;
  }
  .cart-complect .cc-nof {
    order: 2;
  }
  .cart-complect .cc-parts {
    font-weight: 600;
    padding-bottom: 8px;
  }
  .cart-complect .cc-parts-props {
    font-weight: 400;
  }
  .cart-complect .cc-image {
    flex: 0 0 30%;
    padding-right: 15px;
    margin-bottom: 20px;
  }
  .cart-complect .cc-data {
    flex: 0 0 70%;
  }
  .cart-complect .cc-spacer {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .cart-complect {
    flex: 0 0 100%;
    padding-left: 30%;
  }
  .cart-complect .cc-complect-title {
    width: 100%;
    font-size: 21px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 30px;
  }
  .cart-complect .cc-image {
    flex: 0 0 24%;
    padding-right: 15px;
    margin-bottom: 14px;
  }
  .cart-complect .cc-data {
    flex: 0 0 90%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }
  .cart-complect .cc-parts {
    flex: 0 0 52%;
    line-height: 17px;
    font-weight: 600;
  }
  .cart-complect .cc-parts-props {
    font-weight: 400;
  }
  .cart-complect .cc-padding {
    flex: 0 0 30%;
  }
  .cart-complect .cc-order {
    flex: 0 0 38%;
  }
  .cart-complect .cc-spacer {
    flex: 0 0 10%;
  }
  .cart-complect .cc-clear {
    flex: 0 0 100%;
  }
  .cart-complect .cc-toggle,
  .cart-complect .cc-title {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
  }
  .cart-complect .cc-title {
    margin-bottom: 16px;
  }
  .cart-complect .cc-fake-image {
    width: calc(12.5vw - 40px);
    height: calc(12.5vw - 40px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cart-complect .cc-order {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .cart-complect .cc-nof {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .cart-complect .cc-parts {
    font-size: 16px;
  }
  .cart-complect .cc-title,
  .cart-complect .cc-toggle {
    font-size: 13px;
  }
  .cart-complect .cc-fake-image {
    width: 83px;
    height: 83px;
  }
}
@media screen and (min-width: 1216px) {
  .cart-complect .cc-fake-image {
    width: 114px;
    height: 114px;
  }
}

.cart-total {
  border: 1px solid #C6C6C6;
  padding: 20px 16px 17px;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: hsl(0, 0%, 0%);
}
.cart-total .button {
  width: 100%;
  padding-left: 2px;
  padding-right: 2px;
  letter-spacing: 0;
  font-size: 12px;
  line-height: 18px;
}
.cart-total .ct-promo {
  display: flex;
}
.cart-total .ct-total {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart-total .ct-total:not(:last-child) {
  margin-bottom: 20px;
}
.cart-total .ct-button:not(:last-child) {
  margin-bottom: 21px;
}
.cart-total .ct-text:not(:last-child) {
  margin-bottom: 12px;
}
@media screen and (max-width: 767px) {
  .cart-total {
    background-color: #ededed;
    font-size: 14px;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cart-total {
    padding: 12px;
  }
  .cart-total .ct-text {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) {
  .cart-total .button {
    padding-left: 5px;
    padding-right: 5px;
    letter-spacing: 0.02em;
  }
}

.cart-clear {
  text-align: right;
}
.cart-clear--absolute {
  position: absolute;
  top: -46px;
  right: 0;
}
@media screen and (max-width: 767px) {
  .cart-clear--absolute {
    top: 2px;
    right: 25px;
  }
}

.cart-clear-btn {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid rgba(135, 135, 135, 0.5);
}
.cart-clear-btn:hover {
  border-bottom-color: rgba(135, 135, 135, 0.9);
}
.cart-clear-btn, .cart-clear-btn:hover {
  text-decoration: none;
}
.cart-clear-btn:focus {
  outline: none;
}