//noinspection SassScssResolvedByNameOnly
.cart-title {
	@include until($tablet) {
		margin-top: -24px;
	}
}

//noinspection SassScssResolvedByNameOnly
.cart-wrapper {
	@include until($tablet) {
		& > .-main:not(:last-child) {
			margin-bottom: 40px;
		}
	}

	@include from($tablet) {
		display: flex;

		& > .-main {
			position: relative;
			flex-grow: 1;
		}

		& > .-sidebar {
			flex: 0 0 260px;
			margin: 0 0 0 20px;
			flex-grow: 0;
			padding-top: 15px;

			.cart-total {
				width: 260px;
			}
		}
	}

	@include from($desktop) {
		& > .-sidebar {
			flex: 0 0 310px;
			margin: 0 0 0 30px;

			.cart-total {
				width: 310px;
			}
		}
	}
}

.sticky {
	position: sticky;
	top: 15px;
	left: 0;
	@include until($mobile) {
		position: static;
	}
}


//noinspection SassScssResolvedByNameOnly
.cart-item {
	display: flex;
	flex-wrap: wrap;
	padding: 6px 0 15px;

	&:not(:last-child) {
		margin-bottom: 26px;
		border-bottom: 1px solid $dfs-line;
	}

	.cart-item {
		flex: 0 0 100%;
		padding-top: 0;
	}

	.ci-also-products {
		margin-bottom: 26px;
		font-weight: 600;
	}

	.ci-image {
		flex: 0 0 30%;
		padding-right: 15px;
		img {
			width: 100%;
		}
	}

	.ci-data {
		flex: 0 0 70%;
	}

	.ci-complect-title {
		flex: 0 0 100%;

		font-size: 17px;
		line-height: 25px;
		margin-bottom: 12px;
	}

	.ci-title {
		text-align: left;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: $letter-spacing;
		a:not([href]) {
			cursor: text;
			&:hover,
			&:active,
			&:focus {
				color: #000;
			}
		}
	}

	.ci-props {
		text-align: left;
		color: #706F6F;
		font-weight: 400;
		font-size: 11px;

		span {
			display: block;
		}
	}


	.ci-order {
		display: flex;
		justify-content: space-between;

	}

	.ci-nof {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;

		.button.is-add,
		.button.is-delete {
			width: 30px;
			height: 30px;
		}

		.input {
			width: 60px;
			margin: 0;
			padding: 0 9px;
			box-sizing: border-box;
			text-align: right;
		}

		.button:not(:last-child),
		.input:not(:last-child) {
			margin-right: 4px;
		}
	}

	.ci-prices {
		text-align: right;
		font-size: 17px;
		line-height: 18px;
		margin-top: -5px;
	}

	.ci-price {
		white-space: nowrap;
		font-size: 12px;
	}

	.ci-cost {
		white-space: nowrap;
		font-weight: 600;
	}

	.ci-delete {
		display: flex;
		justify-content: flex-end;

		& > a {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;
			margin-top: -4px;

			&,
			&:hover {
				background-color: transparent;
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				background-color: #C6C6C6;
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
				transform-origin: center center;
			}

			&:before {
				width: 50%;
				height: 1px;
			}

			&:after {
				width: 1px;
				height: 50%;
			}

			&:hover {
				&:before,
				&:after {
					background-color: #878787;
				}
			}
		}
	}

	@include until($tablet) {
		margin-left: -$body-padding-hm;
		margin-right: -$body-padding-hm;
		padding-left: $body-padding-hm;
		padding-right: $body-padding-hm;
		width: 100vw;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		.cart-item {
			position: relative;
			padding-top: 0;
		}

		.ci-order {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}

		.ci-price {
			margin-right: 6px;
		}

		.ci-prices {
			order: 1;
			display: flex;
			align-items: baseline;
			margin-bottom: 6px;
		}

		.ci-nof {
			order: 2;
		}

		.ci-delete {
			position: absolute;
			top: 0;
			right: -20px;
			margin-top: 0;
		}

		.ci-title {
			padding-bottom: 10px;
		}
	}

	@include from($tablet) {
		padding: 11px 0 16px;

		.ci-complect-title {
			font-size: 19px;
			line-height: 1.2;
			text-transform: uppercase;
			letter-spacing: $letter-spacing;
			margin-bottom: 30px;
		}

		.ci-data {
			flex: 0 0 70%;
			display: flex;
		}

		.ci-image {
			flex: 0 0 30%;
			padding-right: 28px;
			a {
				display: block;
			}
		}

		.ci-title {
			flex: 0 0 52%;
			font-size: 13px;
			line-height: 17px;
			padding-bottom: 20px;
			padding-right: 20px;
		}

		.ci-order {
			flex: 0 0 38%;
			justify-content: flex-start;
		}

		.ci-delete {
			width: 10%;
		}
	}

	@include tablet-only {
		.ci-order {
			flex-direction: column;
		}

		.ci-nof {
			margin-bottom: 16px;
		}
	}

	@include from($desktop) {
		.ci-title {
			font-size: 16px;
		}

		.ci-props {
			display: flex;
			flex-direction: column;
			font-size: 12px;
			line-height: 1.5;
			padding-top: 2px;
		}

		.ci-order {
			justify-content: space-between;
			gap: 8px;
		}
	}
}

.modal-scroll-box {
	@include until($tablet) {
		.cart-item {
			position: relative;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
			.ci-title {
				font-size: 18px;
				padding-right: 15px;
				line-height: 1.2;
			}
			.ci-delete {
				top: 0;
				margin-top: 0;
				right: 0;
			}
		}
	}
}

//noinspection SassScssResolvedByNameOnly
.cart-complect {
	display: flex;
	flex-wrap: wrap;

	.cc-title {
		flex: 0 0 100%;
		font-size: 14px;
		line-height: 1.2;
	}

	.cc-parts {
		font-size: 14px;
		line-height: 17px;
		letter-spacing: $letter-spacing;
	}

	.cc-parts-props {
		font-size: 11px;
		line-height: 18px;
		color: #706F6F;
		letter-spacing: 0;
		display: block;
	}

	.cc-order {
		display: flex;
		justify-content: space-between;
	}

	.cc-nof {
		//padding-left: 34px;

		.input {
			width: 40px;
			margin: 0;
			padding: 0;
			text-align: center;
		}

		.button:not(:last-child),
		.input:not(:last-child) {
			margin-right: 4px;
		}
	}

	.cc-prices {
		text-align: right;
		font-size: 17px;
		line-height: 18px;
		margin-top: -5px;
	}

	.cc-price {
		white-space: nowrap;
		font-size: 12px;
	}

	.cc-cost {
		font-weight: 600;
	}

	.cc-toggle {
		cursor: pointer;
		position: relative;
		display: inline-block;
		line-height: 1.2;

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: -18px;
			top: 8px;
			width: 10px;
			height: 6px;
			box-sizing: border-box;
			background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='#1D1D1B' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.68 1.189L4.867 6 0 1.189 1.189 0l3.679 3.623L8.49 0l1.188 1.189z'/%3E%3C/svg%3E%0A");
		}

		&.is-active::after {
			transform: rotate(180deg);
			top: 6px;
		}
	}

	.cc-fake-image {
		display: block;
		width: calc(30vw - 40px);
		height: calc(30vw - 40px);
		border: 1px solid $dfs-grey-middle;
	}

	@include until($tablet) {
		margin: 30px 0 -30px;
		padding-bottom: 10px;

		&.is-opened {
			margin-bottom: -30px;
			padding-top: 10px;
		}

		.cc-title {
			padding: 2px 0 10px;
			color: $dfs-red;

			.cc-toggle:after {
				background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='#BB0026' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.68 1.189L4.867 6 0 1.189 1.189 0l3.679 3.623L8.49 0l1.188 1.189z'/%3E%3C/svg%3E%0A");
			}
		}

		.cc-toggle {
			padding-bottom: 8px;
		}

		&.is-opened,
		&.is-opened + .cart-complect {
			margin-left: -$body-padding-hm;
			margin-right: -$body-padding-hm;
			padding-left: $body-padding-hm;
			padding-right: $body-padding-hm;
			width: 100vw;
			background: #EDEDED;
		}

		.cc-order {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 8px;
		}

		.cc-price {
			margin-right: 6px;
		}

		.cc-prices {
			order: 1;
			display: flex;
			align-items: baseline;
			margin-bottom: 6px;
		}

		.cc-nof {
			order: 2;
		}

		.cc-parts {
			font-weight: 600;
			padding-bottom: 8px;
		}

		.cc-parts-props {
			font-weight: 400;
		}

		.cc-image {
			flex: 0 0 30%;
			padding-right: 15px;
			margin-bottom: 20px;
		}

		.cc-data {
			flex: 0 0 70%;
			//margin-bottom: 20px;
		}

		.cc-spacer {
			display: none;
		}
	}

	@include from($tablet) {
		flex: 0 0 100%;
		padding-left: 30%;

		.cc-complect-title {
			width: 100%;
			font-size: 21px;
			line-height: 1.2;
			text-transform: uppercase;
			letter-spacing: $letter-spacing;
			margin-bottom: 30px;
		}

		.cc-image {
			flex: 0 0 24%;
			padding-right: 15px;
			margin-bottom: 14px;
		}

		.cc-data {
			//flex: 0 0 66%;
			flex: 0 0 90%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 14px;
		}

		.cc-parts {
			//flex: 0 0 28%;
			flex: 0 0 52%;
			line-height: 17px;
			font-weight: 600;
		}

		.cc-parts-props {
			font-weight: 400;
		}

		.cc-padding {
			flex: 0 0 30%;
		}

		.cc-order {
			flex: 0 0 38%;
		}

		.cc-spacer {
			flex: 0 0 10%;
		}

		.cc-clear {
			flex: 0 0 100%;
		}

		.cc-toggle,
		.cc-title {
			font-size: 13px;
			font-weight: 600;
			line-height: 19px;
		}

		.cc-title {
			margin-bottom: 16px;
		}

		.cc-fake-image {
			width: calc(12.5vw - 40px);
			height: calc(12.5vw - 40px);
		}
	}

	@include tablet-only {
		.cc-order {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-end;
		}

		.cc-nof {
			margin-bottom: 10px;
		}
	}

	@include from($desktop) {
		.cc-parts {
			font-size: 16px;
		}

		.cc-title,
		.cc-toggle {
			font-size: 13px;
		}

		.cc-fake-image {
			width: 83px;
			height: 83px;
		}
	}

	@include from($widescreen) {
		.cc-fake-image {
			width: 114px;
			height: 114px;
		}
	}
}

//noinspection SassScssResolvedByNameOnly
.cart-total {
	border: 1px solid #C6C6C6;
	padding: 20px 16px 17px;
	font-size: 13px;
	line-height: 17px;
	letter-spacing: $letter-spacing;
	color: $black;

	.button {
		width: 100%;
		padding-left: 2px;
		padding-right: 2px;
		letter-spacing: 0;
		font-size: 12px;
		line-height: 18px;
	}

	.ct-promo {
		display: flex;
	}

	.ct-total {
		font-size: 17px;
		line-height: 24px;
		font-weight: 700;
		text-transform: uppercase;


		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	.ct-button {
		&:not(:last-child) {
			margin-bottom: 21px;
		}
	}

	.ct-text {
		&:not(:last-child) {
			margin-bottom: 12px;
		}
	}

	@include until($tablet) {
		background-color: #ededed;
		font-size: 14px;
		line-height: 18px;
		padding-left: 10px;
		padding-right: 10px;
	}

	@include tablet-only {
		padding: 12px;

		.ct-text {
			font-size: 14px;
		}
	}

	@include from($tablet) {
		.button {
			padding-left: 5px;
			padding-right: 5px;
			letter-spacing: 0.02em;
		}
	}
}

.cart-clear {
	text-align: right;
	&--absolute {
		position: absolute;
		top: -46px;
		right: 0;
	}
	@include until($tablet) {
		&--absolute {
			top: 2px;
			right: 25px;
		}
	}
}
.cart-clear-btn {
	padding: 0;
	background: transparent;
	border: none;
	cursor: pointer;
	@include border-bottom($dfs-grey-middle);
	&:focus {
		outline: none;
	}
}