// Change colors scheme
$primary: hsl(171, 100%, 41%);
$link: hsl(204, 86%, 53%);
$info: hsl(217, 71%, 53%);
$success: hsl(141, 71%, 48%);
$warning: hsl(48, 100%, 67%);
$danger: hsl(348, 100%, 61%);
$text: hsl(0, 0%, 29%);
$title: hsl(0, 0%, 21%);
$navy: hsl(210, 100%, 12%);
$blue: hsl(208, 100%, 43%);
$aqua: hsl(197, 100%, 75%);
$teal: hsl(180, 59%, 51%);
$olive: hsl(153, 43%, 42%);
$green: hsl(127, 63%, 49%);
$lime: hsl(146, 100%, 50%);
$yellow: hsl(52, 100%, 50%);
$orange: hsl(28, 100%, 55%);
$red: hsl(3, 100%, 61%);
$maroon: hsl(331, 74%, 30%);
$fuchsia: hsl(314, 88%, 51%);
$purple: hsl(292, 88%, 42%);
$black: hsl(0, 0%, 0%);
$gray: hsl(0, 0%, 67%);
$silver: hsl(0, 0%, 87%);
$white: hsl(0, 0%, 100%);
//mcs-color-scheme
//!mcs-color-scheme
//!mcs-color-scheme

$body-background-color: #fff;
$body-min-width: 280px;
$body-family: system,-apple-system,BlinkMacSystemFont,'Segoe UI','Segoe WP',"Trebuchet MS","Roboto,Ubuntu","SF Pro Display","SF Pro Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
$body-font-size: 15px;
$content-font-size: 15px;
$body-line-height: 1.5;
$body-color: #000;
$body-weight: 400;
$body-padding-h: 20px;
$body-padding-hm: 20px;
$body-padding-v: 34px;
$body-padding-vm: 19px;

$block-spacing: 1.5rem;

$letter-spacing: 0.02em;

$dfs-scarlet: #D5131A;
$dfs-red: #BB0026;
$dfs-grey: #1D1D1B;
$dfs-grey-dark: #706F6F;
$dfs-grey-middle: #878787;
$dfs-grey-light: #EDEDED;
$dfs-grey-timer: #B9B9B9;
$dfs-line: #DADADA;
$dfs-filter-line: #C4C4C4;
$dfs-filter-input-line: #C6C6C6;
$dfs-btn-grey: #555454;
$dfs-btn-geo-close: #cecece;
$dfs-label-new: #2d7e2d;
$dfs-banner-top: #fff700;
$dfs-label-sale: #FF8227;
$dfs-personal-tab: #E5E5E5;
$dfs-radio-btn: #636363;

$link: #000;
$link-hover: $dfs-red;

$size-8: 0.875em !default;

$modal-card-head-padding: 30px 20px 20px !default;
$modal-close-top: 5px !default;
$modal-close-right: 5px !default;
$modal-close-dimensions: 40px !default;

// Rating color
$rating-primary-color: #ffc700;
$rating-hover-color: #deb217;
$rating-checked-color: #c59b08;

$strong-color: inherit;

$footer-panel-height: 60px;

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$mobile-small: 319px;
$mobile: 576px;
$mobile-wide: 641px;
$tablet: 768px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap);
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);

$container-offset: (2 * $gap);

// Container width
$container-width-desktop: $desktop - 2 * $gap;
$container-width-widescreen: $widescreen - 2 * $gap;
$container-width-fullhd: $fullhd - 2 * $gap;
