@mixin size ($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin generate-text-color($status, $color) {
	.has-text-#{"" + $status} {
		color: $color;
	}
}

@mixin generate-background-color($status, $color) {
	.has-background-#{"" + $status} {
		background-color: $color;
	}
}

@mixin generate-style($status, $color) {
	.has-style-#{"" + $status} {
		background-color: $color;
	}
}

@mixin generate-style-light($status, $color) {
	.has-style-light-#{"" + $status} {
		background-color: transparentize($color, .85) !important;
	}
}

@mixin generate-color-white($status, $color) {
	//.has-style-#{$status} {
	//	&, & *:not([class*="has-text-"]) {
	//		color: $white !important;
	//	}
	//}
}

@mixin generate-background-yandex-color($status, $color) {
	.has-background-yandex-#{"" + $status} {
		background-color: $color !important;
	}
}

@mixin block($spacing: $block-spacing) {
	&:not(:last-child) {
		margin-bottom: $spacing
	}
}

// Responsive
@mixin from($device) {
	@media screen and (min-width: $device) {
		@content
	}
}

@mixin until($device) {
	@media screen and (max-width: $device - 1px) {
		@content
	}
}

@mixin mobile {
	@media screen and (max-width: $tablet - 1px) {
		@content
	}
}

@mixin tablet {
	@media screen and (min-width: $tablet), print {
		@content
	}
}

@mixin mobile-wide {
	@media screen and (min-width: $mobile-wide), print {
		@content
	}
}

@mixin tablet-only {
	@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
		@content
	}
}

@mixin tablet-to-widescreen {
	@media screen and (min-width: $tablet) and (max-width: $widescreen - 1px) {
		@content
	}
}

@mixin touch {
	@media screen and (max-width: $desktop - 1px) {
		@content
	}
}

@mixin desktop {
	@media screen and (min-width: $desktop) {
		@content
	}
}

@mixin desktop-only {
	@media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
		@content
	}
}

@mixin widescreen {
	@media screen and (min-width: $widescreen) {
		@content
	}
}

@mixin widescreen-only {
	@media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
		@content
	}
}

@mixin fullhd {
	@media screen and (min-width: $fullhd) {
		@content
	}
}

@mixin border-bottom ($color: $black) {
	border-bottom: 1px solid transparentize($color, .5);

	&:hover {
		border-bottom-color: transparentize($color, .1);
	}

	&, &:hover {
		text-decoration: none;
	}
}

@mixin block-spacing {
	&:not(:last-child) {
		margin-bottom: $block-spacing;
	}
}

@mixin svg-fill-color {
	svg {
		fill: currentColor;
	}
}

@mixin count($size: 20px, $font: 13px, $right: -15px, $top: -9px) {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: $font;
	line-height: 1;
	color: $white;
	width: $size;
	height: $size;
	box-sizing: border-box;
	background: $dfs-red;
	border-radius: 10px;
	text-align: center;
	position: absolute;
	right: $right;
	top: $top;
}

@mixin triangle($size, $width, $color, $colorHover, $offsetX: -2.8, $offsetY: -2px, $radius: 2px, $angle: 135deg) {
	position: relative;
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: calc(50% + #{$offsetY});
		right: $size*$offsetX;
		width: $size;
		height: $size;
		border-color: $color $color transparent transparent;
		border-style: solid;
		border-width: $width;
		box-sizing: border-box;
		transform: rotate($angle);
		border-radius: $radius;
	}
	&:hover {
		&::before {
			border-color: $colorHover $colorHover transparent transparent;
		}
	}

}

@mixin scrollbar($size: 10px) {
	&::-webkit-scrollbar{
		width: $size;
		background-color: $dfs-grey-light;
		&-thumb {
			background-color: $dfs-grey-middle;
			border-radius: $size;
		}
	}
}
